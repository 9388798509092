export const calcularDiferenciaDias = (
  fechaObjetivo: string | null,
): number => {
  if (!fechaObjetivo) return 0;
  const fechaObjetivoDate = new Date(fechaObjetivo);

  // Obtener la fecha actual
  const fechaActual = new Date();

  // Asegurarse de que ambas fechas estén al inicio del día (sin horas, minutos, segundos)
  fechaObjetivoDate.setHours(0, 0, 0, 0);
  fechaActual.setHours(0, 0, 0, 0);

  // Calcular la diferencia en milisegundos
  const diferenciaMilisegundos =
    fechaActual.getTime() - fechaObjetivoDate.getTime();

  // Convertir milisegundos a días
  const diasDiferencia = Math.round(
    diferenciaMilisegundos / (1000 * 60 * 60 * 24),
  );

  return diasDiferencia;
};
