import * as React from "react"
import { format } from "date-fns"
import { es } from "date-fns/locale"
import { DayPicker } from "react-day-picker"
import "react-day-picker/style.css";
import { ChevronLeft, ChevronRight } from 'lucide-react'

interface CustomDatePickerProps {
    value: Date;
    onChange: (date: Date) => void;
    isOpen: boolean;
    setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export const CustomDatePicker: React.FC<CustomDatePickerProps> = ({ value, onChange, isOpen, setIsOpen }) => {

    const calendarRef = React.useRef<HTMLDivElement>(null)
    React.useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (calendarRef.current && !calendarRef.current.contains(event.target as Node)) {
                setIsOpen(false)
            }
        }

        if (isOpen) {
            document.addEventListener("mousedown", handleClickOutside)
        }

        return () => {
            document.removeEventListener("mousedown", handleClickOutside)
        }
    }, [isOpen, setIsOpen])



    return (
        <>
            {isOpen && (
                <div
                    ref={calendarRef}
                    className='absolute top-full left-0 z-50 mt-2 bg-white rounded-lg shadow-lg border'
                >

                    <DayPicker
                        mode="single"
                        selected={value}
                        showOutsideDays
                        onSelect={(date) => {
                            if (date)
                                onChange(date)
                        }}
                        formatters={{
                            formatWeekdayName: (day) =>
                                day.toLocaleDateString('es-ES', { weekday: 'narrow' }),
                        }}
                        locale={es}
                        className="p-3"
                        modifiers={{
                            today: (date) => date.toDateString() === new Date().toDateString(),
                            selected: (date) => date.toDateString() === value?.toDateString() && !(date.toDateString() === new Date().toDateString()),

                        }}
                        classNames={{
                            outside: 'text-NeutralNeutral4',
                            day: 'button16 text-NeutralNeutral2',
                            month: 'button16 text-red-500',
                            month_caption: 'button18 text-NeutralNeutral1',
                            weekdays: 'button16 text-NeutralNeutral2',

                        }}
                        modifiersClassNames={{
                            selected: 'bg-CorporatePrimary text-white rounded-md',
                            today: 'text-[#32BB78] ',
                        }}

                        components={{
                            Chevron: (props) => {
                                if (props.orientation === "left") {
                                    return <ChevronLeft className="size-8 p-2 rounded-md bg-slate-200 text-NeutralNeutral2" />;
                                }
                                return <ChevronRight className="size-8 p-2 rounded-md bg-slate-200 text-NeutralNeutral2" />;
                            },
                        }}
                    />
                </div>
            )}
        </>
    )
}

