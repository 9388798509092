import React from 'react'

interface MobileMenuButtonProps {
    isOpen: boolean;
    toggleMenu: () => void;
}

export const MobileMenuButton: React.FC<MobileMenuButtonProps> = ({ isOpen, toggleMenu }) => {

    return (
        <div className="flex items-center gap-4 md:hidden">
            <button onClick={toggleMenu} className="bg-NeutralNeutral6 rounded-full flex flex-col items-center gap-1.5 size-8 justify-center md:hidden">
                <span className={`w-4 h-0.5 bg-NeutralNeutral2 rounded-md duration-200 origin-top-left ${isOpen ? 'rotate-45 translate-x-px' : ''}`}></span>
                <span className={`w-4 h-0.5 bg-NeutralNeutral2 rounded-md duration-200 origin-bottom-left ${isOpen ? '-rotate-45 -translate-x-px' : ''}`}></span>
            </button>
            <p className="bodylbold text-NeutralNeutral1">Documentos</p>
        </div>
    )
}