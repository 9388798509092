import CloseIcon from '../../shared/Icons/CloseIcon';
import { useApiDeveloper } from '../hooks/useApiDeveloper';
import { Permission } from '../types/RolesYPermissions';

export const PermissionsInfo = ({ permiso }: { permiso: Permission }) => {
    const { deletePermissionMutation } = useApiDeveloper();
    const onRemovePermission = (id: number) => {
        if (window.confirm("¿Esta seguro que desea eliminar este permiso?")) {
            deletePermissionMutation.mutate(id)
        }
    }
    return (
        <div className='flex justify-between items-center'>
            <div className='text-left text-NeutralNeutral3 bodym' >
                {permiso.id} - {permiso.name}
            </div>
            <CloseIcon
                size={20}
                className='ml-2'
                onClick={() => onRemovePermission(Number(permiso.id))}
                disabled={deletePermissionMutation.isPending} />
        </div>
    )
}
