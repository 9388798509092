import React from 'react'
import { useDeveloperContext } from '../context/DeveloperContext';
import { useApiDeveloper } from '../hooks/useApiDeveloper';
import { Role } from '../types/RolesYPermissions';

export const AddPermissionToRoleModal = () => {

    const { setSelectedPermission, selectedRole, setIsAddPermissionToRoleModalOpen, selectedPermission } = useDeveloperContext();

    const { addPermissionMutation, addPermissionToRoleMutation, fetchPermissionsData } = useApiDeveloper();

    const getAvailablePermissions = (role: Role) => {
        return (
            fetchPermissionsData?.filter(
                (permission) => !role.permissions.some((rolePermission) => rolePermission.id === permission.id),
            ) || []
        )
    }
    const onAddPermissionToRole = (permissionId: number | null) => {
        if (selectedRole && permissionId) {
            addPermissionToRoleMutation.mutate({ roleId: Number(selectedRole.id), permissionId })
        }
    }

    return (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
            <div className="bg-white p-6 rounded-lg">
                <h2 className="bodylbold text-NeutralNeutral1 mb-4">Añadir permiso a {selectedRole?.name}</h2>
                <select
                    className="w-full p-2 border rounded mb-4"
                    onChange={(e) => setSelectedPermission(Number(e.target.value))}
                >
                    <option value="">Elige permiso</option>
                    {getAvailablePermissions(selectedRole!).map((permission) => (
                        <option key={permission.id} value={permission.id}>
                            {permission.name}
                        </option>
                    ))}
                </select>
                <div className="flex justify-end">
                    <button className="px-4 py-2 bg-gray-200 rounded mr-2" onClick={() => setIsAddPermissionToRoleModalOpen(false)}>
                        Cancelar
                    </button>
                    <button
                        className="px-4 py-2 bg-blue-500 text-white rounded"
                        onClick={() => {
                            onAddPermissionToRole(selectedPermission)
                            setIsAddPermissionToRoleModalOpen(false)

                        }}
                        disabled={addPermissionToRoleMutation.isPending}
                    >
                        Añadir
                    </button>
                </div>
            </div>
        </div>
    )
}
