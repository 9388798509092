import React from "react";
import { AppRouter } from "./router/Router";
import { AuthContextProvider } from "./auth/context/AuthContext";
import "react-toastify/dist/ReactToastify.css";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import "./shared/utils/i18n/i18n";
import { ToastContainer } from "react-toastify";

const queryClient = new QueryClient();
function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <AuthContextProvider>
        <ToastContainer />
        <AppRouter />
        <ReactQueryDevtools />
      </AuthContextProvider>
    </QueryClientProvider>
  );
}
export default App;
