
interface SummaryStatisticsBlockProps {
    title: string;
    description: string;
    children?: React.ReactNode;
}

export const SummaryStatisticsBlock: React.FC<SummaryStatisticsBlockProps> = ({ title, description, children }) => {
    return (
        <section className='flex flex-col gap-2 p-4 bg-white rounded-lg'>
            <p className=' h6 text-NeutralNeutral1'>{title}</p>
            <p className=' bodym text-NeutralNeutral2 pb-4'>{description}</p>
            <div className="flex flex-col gap-5">
                {children}
            </div>


        </section>
    )
}
