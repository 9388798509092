'use client'
import type React from "react"
import { useEffect, useRef, useState } from "react"
import { Document, Page, pdfjs } from "react-pdf"
import "react-pdf/dist/esm/Page/AnnotationLayer.css"
import "react-pdf/dist/esm/Page/TextLayer.css"
import { Size } from "../../Templates/types"

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`;


interface PDFViewerProps {
    file: File | null
    currentPage: number
    onDocumentLoadSuccess: ({ numPages }: { numPages: number }) => void
    children?: React.ReactNode
    handlePageLoadSuccess?: (page: any) => void
    pdfOriginalDimensions: Size
}

export const PDFViewer: React.FC<PDFViewerProps> = ({
    file,
    currentPage,
    onDocumentLoadSuccess,
    children,
    handlePageLoadSuccess,
    pdfOriginalDimensions
}) => {

    const containerRef = useRef<HTMLDivElement>(null)
    const [scaledWidth, setScaledWidth] = useState(1)

    const updateScale = () => {
        if (containerRef.current) {
            const { clientWidth, clientHeight } = containerRef.current
            const { width: pdfWidth, height: pdfHeight } = pdfOriginalDimensions

            // Escalar PDF manteniendo la relación de aspecto
            const scaleFactor = Math.min(clientWidth / pdfWidth, clientHeight / pdfHeight)
            setScaledWidth(pdfWidth * scaleFactor)
        }
    }

    useEffect(() => {
        updateScale()
        window.addEventListener("resize", updateScale)
        return () => window.removeEventListener("resize", updateScale)
    }, [pdfOriginalDimensions])

    if (!file) {
        return null
    }

    return (
        <div ref={containerRef} className="w-full h-full flex justify-center items-center overflow-hidden">
            <Document
                file={file}
                onLoadSuccess={onDocumentLoadSuccess}
                className="relative"
            >
                <Page
                    pageNumber={currentPage}
                    renderTextLayer={false}
                    renderAnnotationLayer={false}
                    onRenderSuccess={handlePageLoadSuccess}
                    width={scaledWidth}
                >
                    {children}
                </Page>
            </Document>
        </div>

    )
}

