import type React from "react"
import { DropZone } from "../../shared/components/DropZone"
import { useDocumentTemplateContext } from "../context/DocumentTemplateContext"
import { useFileTemplate } from "../hooks/useFileTemplate"

export const DocumentTemplateUploader: React.FC = () => {

    const { fileTemplate, setFileTemplate } = useDocumentTemplateContext();

    const { removeAllFileTemplate } = useFileTemplate()

    const acceptedFileTypeTemplate = {
        "application/pdf": [".pdf"],
    }

    return (
        <>
            <div className="w-3/5 h-1/3">
                <DropZone
                    files={fileTemplate}
                    onFilesChange={setFileTemplate}
                    acceptedFileTypes={acceptedFileTypeTemplate}
                    onRemoveAllFiles={removeAllFileTemplate}
                />
            </div>
            <p className="bodym text-NeutralNeutral2">Formato aceptado: PDF</p>
            <p className="bodym text-NeutralNeutral2">Tamaño máximo: 10 MB</p>
        </>
    )
}

