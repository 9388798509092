import axios from "axios";
import Cookies from "js-cookie";
import { refreshToken } from "../utils/token/RefreshToken";
import { showToast } from "../components/showCustomToast";

const API_BASE_URL = (process.env.REACT_APP_API_URL as string) + "/api/";

const api = axios.create({
  baseURL: API_BASE_URL,
});

api.interceptors.request.use((config) => {
  const token = Cookies.get("accessToken");
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

api.interceptors.response.use(
  (response) => response,
  async (error) => {
    if (error.response && error.response.status === 418) {
      showToast({
        title: "Error",
        message: error.response.data.detail,
        icon: "error",
      });
    }
    const originalRequest = error.config;
    if (error.response?.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      try {
        const newAccessToken = await refreshToken();
        originalRequest.headers.Authorization = `Bearer ${newAccessToken}`;
        return api(originalRequest);
      } catch (refreshError) {
        Cookies.remove("accessToken");
        Cookies.remove("refreshToken");
        window.dispatchEvent(new Event("tokenRefreshFailed"));
        return Promise.reject(refreshError);
      }
    }
    return Promise.reject(error);
  },
);

export default api;
