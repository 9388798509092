import { useEffect, useRef, useState } from 'react'
import { ChevronDown } from 'lucide-react'

interface DropdownProps {
    label: string
    options: string[]
    value: string
    onChange: (value: string) => void
}

export function Dropdown({ options, value, onChange }: DropdownProps) {
    const [isOpen, setIsOpen] = useState(false)
    const dropdownRef = useRef<HTMLDivElement>(null)
    useEffect(() => {
        function handleClickOutside(event: MouseEvent) {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
                setIsOpen(false)
            }
        }

        document.addEventListener("mousedown", handleClickOutside)
        return () => document.removeEventListener("mousedown", handleClickOutside)
    }, [])

    return (
        <div className="relative" ref={dropdownRef}>

            <button
                type="button"
                className="flex gap-4 w-fit bg-NeutralNeutral6 px-4 py-2 text-right button16 text-NeutralNeutral1  items-center justify-end rounded-lg hover:bg-gray-100  focus:ring-1 focus:ring-inset focus:border-2 focus:border-CorporatePrimary"
                onClick={() => setIsOpen(!isOpen)}
                aria-haspopup="listbox"
                aria-expanded={isOpen}
            >
                <span>{value}</span>
                <ChevronDown
                    className={`w-4 h-4 transition-transform ${isOpen ? 'rotate-180' : ''
                        }`}
                />
            </button>
            {isOpen && (
                <ul
                    className="absolute z-10 w-full mt-1 bg-white border border-gray-200 rounded-lg shadow-lg max-h-60 overflow-auto"
                    role="listbox"
                >
                    {options.map((option) => (
                        <li
                            key={option}
                            className={`px-4 py-2 button14 cursor-pointer hover:bg-gray-100 ${value === option ? 'bg-gray-50' : ''
                                }`}
                            onClick={() => {
                                onChange(option)
                                setIsOpen(false)
                            }}
                            role="option"
                            aria-selected={value === option}
                        >
                            {option}
                        </li>
                    ))}
                </ul>
            )}
        </div>
    )
}

