import React from 'react'

interface EyeOffIconProps {
    className?: string
    size?: number
}

export const EyeOffIcon: React.FC<EyeOffIconProps> = ({
    className = '',
    size = 16,
}) => {
    return (
        <svg
            width={size}
            height={size}
            viewBox="0 0 14 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={className}
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M2.28033 0.21967C1.98744 -0.0732233 1.51256 -0.0732233 1.21967 0.21967C0.926777 0.512563 0.926777 0.987437 1.21967 1.28033L11.7197 11.7803C12.0126 12.0732 12.4874 12.0732 12.7803 11.7803C13.0732 11.4874 13.0732 11.0126 12.7803 10.7197L11.4577 9.397C12.438 8.58631 13.1937 7.51366 13.6176 6.2863C13.681 6.10274 13.6811 5.90313 13.6179 5.71951C12.672 2.97316 10.0653 1 6.99777 1C5.85414 1 4.77457 1.27425 3.82123 1.76057L2.28033 0.21967ZM5.47602 3.41536L6.61147 4.55081C6.73539 4.51767 6.86563 4.5 7 4.5C7.82843 4.5 8.5 5.17157 8.5 6C8.5 6.13437 8.48233 6.26461 8.44919 6.38853L9.58464 7.52398C9.84855 7.07734 10 6.55636 10 6C10 4.34315 8.65685 3 7 3C6.44364 3 5.92266 3.15145 5.47602 3.41536Z"
                fill="currentColor"
            />
            <path
                d="M6.81206 8.99421L8.62754 10.8097C8.10513 10.9341 7.56002 11 6.99952 11C3.93197 11 1.32527 9.02684 0.379396 6.28049C0.316157 6.09687 0.316245 5.89727 0.379647 5.71371C0.636746 4.96935 1.01588 4.28191 1.49314 3.67529L4.00579 6.18794C4.09895 7.69509 5.30491 8.90105 6.81206 8.99421Z"
                fill="currentColor"
            />
        </svg>
    )
}

