
import React, { ComponentType, ReactElement } from 'react';

export enum ButtonSize {
  small = "button14",
  medium = "button16",
  large = "button18",
}

interface ButtonProps {
  text: string;
  size: ButtonSize;
  icon?: ComponentType<{ className?: string }>;
  isDisabled?: boolean;
  hasBg?: boolean;
  hasBorder?: boolean;
  onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  roundedMd?: boolean;
  noPadding?: boolean;
}

const Button = ({ text, icon: Icon, isDisabled, hasBg, hasBorder, onClick, size, roundedMd, noPadding, ...props }: ButtonProps): ReactElement => {
  return (
    <button
      onClick={onClick}
      disabled={isDisabled}
      className={`
        ${isDisabled ? "bg-NeutralNeutral6" : hasBg ? "bg-CorporatePrimary" : "bg-white"}
        ${hasBorder ? "border-2 border-CorporatePrimary" : ""}
        ${size}
        ${roundedMd ? "rounded-md" : ""}
        ${noPadding ? "p-0" : "py-1 px-4"}
         whitespace-nowrap rounded-3xl shadow-md flex items-center justify-center gap-2 w-full `
      }
      {...props}>
      <span className={` 
        ${isDisabled ? "text-NeutralNeutral2" : hasBg ? "text-white" : hasBorder ? "text-CorporatePrimary" : "text-NeutralNeutral2"}
        `}>
        {text}
      </span>
      {Icon && <Icon className="w-4 h-4" />}
    </button>
  );
};

export default Button;