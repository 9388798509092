import React from "react";
import { useTranslation } from "react-i18next";
import {
  Navigate,
  Route,
  BrowserRouter as Router,
  Routes,
} from "react-router-dom";
import { useAuthContext } from "../auth/context/AuthContext";
import { AuthRoutes } from "../auth/routes/AuthRoutes";
import { TopNavUnauthenticatedLayout } from "../shared/layouts/TopNavUnauthenticatedLayout";
import { TranslationKeys } from "../shared/utils/i18n/TranslationKeys";
import { ValidationPage } from "../validation/pages/ValidationPage";
import { PrivateRoutes } from "./PrivateRoutes";
import { ROUTES } from "./routes";
import { DevelopersRoutes } from "../developers/routes/DevelopersRoutes";
export const AppRouter = () => {
  const { isAuthenticated, isAuthLoading } = useAuthContext();
  const { t } = useTranslation();
  return (
    <Router>
      <Routes>
        {DevelopersRoutes()}
        {AuthRoutes()}
        <Route
          element={<TopNavUnauthenticatedLayout backButtonVisible="true" />}
        >
          <Route
            path={`${ROUTES.VALIDACION}/:signProcessId`}
            element={<ValidationPage />}
          />
        </Route>

        <Route
          path="*"
          element={
            isAuthenticated ? (
              <PrivateRoutes />
            ) : (
              <Navigate to={ROUTES.EXTERNALLOGIN} replace />
            )
          }
        />
      </Routes>
    </Router>
  );
};
