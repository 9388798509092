import React from 'react';

interface DownArrowIconProps {
    size?: number
    color?: string
    className?: string
}

export default function DownArrowIcon({ size = 22, color = "#101216", className = "" }: DownArrowIconProps) {
    return (
        <svg
            width={size}
            height={size}
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={className}
        >
            <rect width="20" height="20" rx="10" fill="#F1F5F9" />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M6.21967 8.21967C6.51256 7.92678 6.98744 7.92678 7.28033 8.21967L10 10.9393L12.7197 8.21967C13.0126 7.92678 13.4874 7.92678 13.7803 8.21967C14.0732 8.51256 14.0732 8.98744 13.7803 9.28033L10.5303 12.5303C10.2374 12.8232 9.76256 12.8232 9.46967 12.5303L6.21967 9.28033C5.92678 8.98744 5.92678 8.51256 6.21967 8.21967Z"
                fill={color}
            />
        </svg>
    );
}