
import Cookies from "js-cookie"
import { createContext, useContext, useEffect, useState } from "react"
import { Outlet } from "react-router-dom"
import { Organization } from "../types/GetMeRequest"
import { UserInfoLocal } from "../types/UserInfoLocal"

interface AuthContextType {
    isAuthenticated: boolean,
    setIsAuthenticated: (isAuthenticated: boolean) => void
    isAuthLoading: boolean,
    setIsAuthLoading: (isAuthLoading: boolean) => void
    userInfoLocal: UserInfoLocal | null;
    setUserInfoLocal: (data: UserInfoLocal | null) => void
    selectedOrg: Organization | null
    setSelectedOrg: (organization: Organization) => void
    organizations: Organization[]
    setOrganizations: (organizations: Organization[]) => void
    nameUser: string
    setNameUser: (nameUser: string) => void
    mailUser: string
    setMailUser: (mailUser: string) => void
    externalUserId: string
    setExternalUserId: (externalUserId: string) => void
    userOrganizationLogged: string
    setUserOrganizationLogged: (userOrganizationLogged: string) => void
}

const AuthContext = createContext<AuthContextType | null>(null)

export const AuthContextProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const [isAuthenticated, setIsAuthenticated] = useState(false)
    const [isAuthLoading, setIsAuthLoading] = useState(true);
    const [userOrganizationLogged, setUserOrganizationLogged] = useState("")
    const [organizations, setOrganizations] = useState<Organization[]>([])
    const [selectedOrg, setSelectedOrg] = useState<Organization | null>(null)
    const [nameUser, setNameUser] = useState("")
    const [mailUser, setMailUser] = useState("")
    const [externalUserId, setExternalUserId] = useState("")
    const [userInfoLocal, setUserInfoLocal] = useState<UserInfoLocal | null>(null)

    useEffect(() => {
        const accessToken = Cookies.get('accessToken')
        setIsAuthenticated(!!accessToken)

        const handleTokenRefreshFailed = () => {
            setIsAuthenticated(false)
        }

        window.addEventListener('tokenRefreshFailed', handleTokenRefreshFailed)
        setIsAuthLoading(false);
        return () => {
            window.removeEventListener('tokenRefreshFailed', handleTokenRefreshFailed)
        }
    }, [])


    return (
        <AuthContext.Provider value={{
            isAuthenticated,
            setIsAuthenticated,
            isAuthLoading,
            selectedOrg,
            setSelectedOrg,
            organizations,
            setOrganizations,
            nameUser,
            setNameUser,
            mailUser,
            setMailUser,
            externalUserId,
            setExternalUserId,
            userOrganizationLogged,
            setUserOrganizationLogged,
            userInfoLocal,
            setUserInfoLocal,
            setIsAuthLoading
        }}>
            {!isAuthLoading ? children : <div>Loading...</div>}
        </AuthContext.Provider>
    )
}

export const useAuthContext = () => {
    const context = useContext(AuthContext)
    if (!context) {
        throw new Error('useAuth must be used within an AuthProvider')
    }
    return context
}