import type React from "react"
import { useDocumentTemplateContext } from "../context/DocumentTemplateContext"
import { Accordion } from "../../shared/components/Accordion"
import { Toggle } from "../../shared/components/Toggle"
import InputField from "../../shared/components/InputField"
import { Helper } from "../../shared/components/Helper"
import { Dropdown } from "../../shared/components/DropDown"

export const ValidationOptions: React.FC = () => {
    const {
        isAñadirTexto,
        setIsAñadirTexto,
        textoLateral,
        setTextoLateral,
        isUrlValidacion,
        setIsUrlValidacion,
        orientationValidation: orientation,
        setOrientationValidation: setOrientation,
        isQR,
        setQR,
        QRData
    } = useDocumentTemplateContext()

    return (
        <div className="flex flex-col gap-3">
            <Accordion title={<p className="bodymbold text-NeutralNeutral2">Texto de validacion</p>}>
                <>
                    <Toggle isChecked={isAñadirTexto} onChange={setIsAñadirTexto} label="Añadir texto" />
                    {isAñadirTexto && (
                        <InputField
                            placeholder="Texto lateral de ejemplo"
                            type="text"
                            value={textoLateral}
                            onChange={(e) => setTextoLateral(e.target.value)}
                            height={24}
                        />
                    )}
                    <div className="flex justify-between">
                        <Toggle isChecked={isUrlValidacion} onChange={setIsUrlValidacion} label="Mostrar URL de validación" />
                        <Helper label="Url que verifica el documento" />
                    </div>
                    {(isAñadirTexto || isUrlValidacion) && (
                        <Dropdown
                            label="Orientación"
                            options={["Vertical", "Horizontal"]}
                            value={orientation}
                            onChange={setOrientation}
                        />
                    )}
                </>
            </Accordion>

            <hr className="bg-NeutralNeutral5" />
            {/* <div>
                Dimensiones QR : {QRData.size.width}px x {QRData.size.height}px
            </div>
            <div>
                Coordenadas QR : {QRData.coordinates.x.toFixed(2)}px x {QRData.coordinates.y.toFixed(2)}px
            </div> */}
            <Accordion title={<p className="bodymbold text-NeutralNeutral2">QR de validación</p>}>
                <Toggle isChecked={isQR} onChange={setQR} label="Mostrar QR" />
            </Accordion>
        </div>
    )
}

