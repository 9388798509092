import React from 'react'
import { useDeveloperContext } from '../context/DeveloperContext';
import { useApiDeveloper } from '../hooks/useApiDeveloper';
import InputField from '../../shared/components/InputField';

export const AddRoleToOrganization = () => {
    const { setIsAddRoleToOrganization, selectedOrganization, setRoleName, roleName } = useDeveloperContext();
    const { addRoleToOrganizationMutation } = useApiDeveloper();
    return (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
            <div className="bg-white p-6 rounded-lg">
                <h2 className="bodylbold text-NeutralNeutral1 mb-4">Añadir role a organizacion: {selectedOrganization?.name}</h2>
                <InputField value={roleName} type='text' placeholder='Nombre del role' onChange={(e) => setRoleName(e.target.value)} />
                <div className="flex justify-end mt-2">
                    <button className="px-4 py-2 bg-gray-200 rounded mr-2" onClick={() => setIsAddRoleToOrganization(false)}>
                        Cancelar
                    </button>
                    <button
                        className="px-4 py-2 bg-blue-500 text-white rounded"
                        onClick={() => {
                            addRoleToOrganizationMutation.mutate({ organizationId: selectedOrganization?.id!, name: roleName })
                            setIsAddRoleToOrganization(false)
                        }}
                        disabled={addRoleToOrganizationMutation.isPending}
                    >
                        Añadir
                    </button>
                </div>
            </div>
        </div>
    )
}
