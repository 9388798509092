import type React from "react"

interface FileIconProps {
    color?: string
    size?: number
    className?: string
}

export const FileIcon: React.FC<FileIconProps> = ({ color = "#94A3B8", size = 14, className = "" }) => {
    return (
        <svg
            width={size}
            height={size}
            viewBox="0 0 14 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={className}
            role="img"
            aria-label="File icon"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M3.28125 0.875C2.67719 0.875 2.1875 1.36469 2.1875 1.96875V12.0312C2.1875 12.6353 2.67719 13.125 3.28125 13.125H10.7188C11.3228 13.125 11.8125 12.6353 11.8125 12.0312V7.4375C11.8125 6.22938 10.8331 5.25 9.625 5.25H8.53125C7.92719 5.25 7.4375 4.76031 7.4375 4.15625V3.0625C7.4375 1.85438 6.45812 0.875 5.25 0.875H3.28125ZM4.375 8.75C4.375 8.50838 4.57088 8.3125 4.8125 8.3125H9.1875C9.42912 8.3125 9.625 8.50838 9.625 8.75C9.625 8.99162 9.42912 9.1875 9.1875 9.1875H4.8125C4.57088 9.1875 4.375 8.99162 4.375 8.75ZM4.8125 10.0625C4.57088 10.0625 4.375 10.2584 4.375 10.5C4.375 10.7416 4.57088 10.9375 4.8125 10.9375H7C7.24162 10.9375 7.4375 10.7416 7.4375 10.5C7.4375 10.2584 7.24162 10.0625 7 10.0625H4.8125Z"
                fill={color}
            />
            <path
                d="M7.56653 1.05928C8.03131 1.59628 8.3125 2.29655 8.3125 3.0625V4.15625C8.3125 4.27706 8.41044 4.375 8.53125 4.375H9.625C10.3909 4.375 11.0912 4.65619 11.6282 5.12097C11.1103 3.13826 9.54924 1.5772 7.56653 1.05928Z"
                fill={color}
            />
        </svg>
    )
}

