
interface IconProps {
    size?: number
    color?: string
    className?: string
}

export default function Fingerprint({ size = 16, color = "#64758B", className = "" }: IconProps) {
    return (
        <svg
            width={size}
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={className}
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M8.00006 3C7.01168 3 6.0924 3.28601 5.31776 3.77951C4.96842 4.00206 4.5048 3.89928 4.28225 3.54994C4.05969 3.20059 4.16247 2.73698 4.51182 2.51442C5.52011 1.87207 6.71778 1.5 8.00006 1.5C11.5899 1.5 14.5001 4.41015 14.5001 8C14.5001 9.66508 14.1674 11.2544 13.5643 12.7036C13.4052 13.086 12.9661 13.267 12.5837 13.1079C12.2013 12.9487 12.0203 12.5097 12.1794 12.1273C12.7079 10.8574 13.0001 9.46379 13.0001 8C13.0001 5.23858 10.7615 3 8.00006 3ZM3.55 4.28219C3.89934 4.50474 4.00212 4.96836 3.77957 5.3177C3.28607 6.09234 3.00006 7.01162 3.00006 8C3.00006 8.41421 2.66427 8.75 2.25006 8.75C1.83584 8.75 1.50006 8.41421 1.50006 8C1.50006 6.71772 1.87213 5.52005 2.51448 4.51176C2.73703 4.16241 3.20065 4.05963 3.55 4.28219ZM8.00006 5.875C6.82645 5.875 5.87506 6.8264 5.87506 8C5.87506 10.002 4.25209 11.625 2.25006 11.625H2.24583L2.21282 11.6248C1.79861 11.6225 1.46472 11.2848 1.46705 10.8706C1.46939 10.4564 1.80706 10.1225 2.22127 10.1248L2.25201 10.125C3.42472 10.1239 4.37506 9.17295 4.37506 8C4.37506 5.99797 5.99803 4.375 8.00006 4.375C10.0021 4.375 11.6251 5.99797 11.6251 8C11.6251 8.07795 11.6241 8.15568 11.6222 8.2332C11.6121 8.64729 11.2682 8.97477 10.8541 8.96465C10.44 8.95452 10.1125 8.61063 10.1227 8.19654C10.1243 8.13123 10.1251 8.06572 10.1251 8C10.1251 6.8264 9.17366 5.875 8.00006 5.875ZM7.99506 7.25C8.40927 7.25 8.74506 7.58579 8.74506 8C8.74506 10.8351 6.93044 13.2444 4.40161 14.1335C4.01085 14.2709 3.5827 14.0655 3.44531 13.6747C3.30791 13.2839 3.51331 12.8558 3.90408 12.7184C5.85109 12.0338 7.24506 10.1787 7.24506 8C7.24506 7.58579 7.58084 7.25 7.99506 7.25ZM10.6463 10.1205C11.0376 10.2563 11.2447 10.6837 11.1089 11.075C10.5227 12.764 9.46807 14.2322 8.10149 15.3251C7.77801 15.5838 7.30605 15.5313 7.04733 15.2078C6.78862 14.8844 6.84112 14.4124 7.16461 14.1537C8.31433 13.2342 9.20012 11.9999 9.69183 10.5832C9.82764 10.1918 10.255 9.98472 10.6463 10.1205Z"
                fill={color}
            />
        </svg>
    )
}