import { useTranslation } from "react-i18next";
import { Link, useLocation } from "react-router-dom";
import { ROUTES } from "../../router/routes";
import ReturnIcon from "../../shared/Icons/ReturnIcon";
import Button, { ButtonSize } from "../../shared/components/Button";
import { TranslationKeys } from "../../shared/utils/i18n/TranslationKeys";

interface UnauthenticatedTopNavProps {
    backButtonVisible?: boolean;
}


export const UnauthenticatedTopNav: React.FC<UnauthenticatedTopNavProps> = ({ backButtonVisible }) => {
    const { t } = useTranslation();
    const location = useLocation()

    return (
        <nav className="flex justify-between items-center w-full h-16 px-8 py-4  ">
            {
                backButtonVisible &&
                (
                    <Link to={ROUTES.VISOR_DOCUMENTOS} className="hidden md:flex">
                        <ReturnIcon />
                    </Link>
                )
            }

            <div className="flex-grow text-left md:text-center">
                <p className="button16 text-NeutralNeutral1">
                    {
                        location.pathname === ROUTES.VALIDACION
                            ? t(TranslationKeys.HerramientaDeValidacion)
                            : t(TranslationKeys.RegistroEnEducaSign)
                    }

                </p>
            </div>

            <Link to={ROUTES.VISOR_DOCUMENTOS}>
                <Button
                    text={t(TranslationKeys.Acceder)}
                    size={ButtonSize.medium}
                    hasBg={false}
                    hasBorder={true}
                />
            </Link>
        </nav>

    )
}