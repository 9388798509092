
const months: string[] = [
  'ene', 'feb', 'mar', 'abr', 'may', 'jun',
  'jul', 'ago', 'sep', 'oct', 'nov', 'dic'
];

const FormatearFechaATexto = (fechaISO: string | null): string => {
    if(!fechaISO) {return "N/A";}
  const date = new Date(fechaISO);

  const day = date.getDate();
  const month = months[date.getMonth()]; // obtenemos el mes en formato abreviado
  const year = date.getFullYear();

  return `${day} de ${month} de ${year}`;
};

export default FormatearFechaATexto;