import type React from "react"
import { Pagination } from "../../shared/components/Pagination"
import { PDFViewer } from "../../shared/components/PdfViewer"
import { useDocumentTemplateContext } from "../context/DocumentTemplateContext"
import { usePagination } from "../hooks/usePagination"
import { useSignature } from "../hooks/useSignature"
import { DraggableBoxQR } from "./ui/DraggableBoxQR"
import { DraggableBoxSignature } from "./ui/DraggableBoxSignature"
import { DraggableBoxURLValidation } from "./ui/DraggableBoxURLValidation"
import { useFileTemplate } from "../hooks/useFileTemplate"

export const DocumentTemplateViewer: React.FC = () => {
    const {
        fileTemplate,
        isUrlValidacion,
        isAñadirTexto,
        isQR,
        pdfOriginalDimensions,
    } = useDocumentTemplateContext()

    const { currentPage, numPages, onDocumentLoadSuccess, handlePageChange } = usePagination()
    const { firmantes, handleSizeSignatureChange, handleCoordinatesSignatureChange } = useSignature()
    const { handlePageLoadSuccess } = useFileTemplate()

    return (
        <div className="w-full h-full relative">
            {/* <div className="absolute bottom-0 left-0 z-10 text-sm text-gray-600 ">
                Dimensiones pdf pantalla: {pdfViewerDimensions.width.toFixed(2)}px x {pdfViewerDimensions.height.toFixed(2)}px
                <br />
                Dimensiones reales del PDF:{pdfOriginalDimensions.width.toFixed(2)}px x {pdfOriginalDimensions.height.toFixed(2)}px
            </div> */}
            <div className="w-full h-full flex justify-center items-center overflow-hidden relative">
                <PDFViewer
                    file={fileTemplate[0]}
                    currentPage={currentPage}
                    onDocumentLoadSuccess={onDocumentLoadSuccess}
                    handlePageLoadSuccess={handlePageLoadSuccess}
                    pdfOriginalDimensions={pdfOriginalDimensions}
                >
                    <div className="absolute inset-0 flex items-center justify-center">
                        {firmantes.map(
                            (firmante, index) =>
                                currentPage === firmante.page && (
                                    <DraggableBoxSignature
                                        key={index}
                                        index={index}
                                        signature={firmante}
                                        currentPage={currentPage}
                                        onSizeChange={(value) => {
                                            handleSizeSignatureChange(index, value)
                                        }}

                                        onCoordinatesChange={(value) => {
                                            handleCoordinatesSignatureChange(index, value)
                                        }}

                                    />
                                ),
                        )}
                        {(isUrlValidacion || isAñadirTexto) && (
                            <DraggableBoxURLValidation />
                        )}
                        {isQR &&
                            <DraggableBoxQR />}
                    </div>
                </PDFViewer>
            </div>
            <div className="absolute bottom-0 left-0 right-0 flex justify-center z-50">
                <Pagination page={currentPage} totalCount={numPages || 0} pageSize={1} onPageChange={handlePageChange} />
            </div>
        </div>
    )
}

