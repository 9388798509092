import React from 'react'

interface ClockIconProps extends React.SVGProps<SVGSVGElement> {
  size?: number
}

export default function ClockIcon({ size = 56, ...props }: ClockIconProps) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 57 56"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect x="0.5" width="56" height="56" rx="28" fill="#F1F5F9" />
      <path
        d="M28.5 20V28H34.5M40.5 28C40.5 34.6274 35.1274 40 28.5 40C21.8726 40 16.5 34.6274 16.5 28C16.5 21.3726 21.8726 16 28.5 16C35.1274 16 40.5 21.3726 40.5 28Z"
        stroke="#64758B"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}