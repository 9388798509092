
import { useState } from 'react';
import { Rnd } from 'react-rnd';
import { GridIcon } from '../../../shared/Icons/GridIcon';
import { useDocumentTemplateContext } from '../../context/DocumentTemplateContext';
import { QRSize } from '../../types';

export const DraggableBoxQR = () => {

    const {
        QRData,
        setQRData,
    } = useDocumentTemplateContext()

    const Box = () => {
        return (
            <div className='relative flex justify-center items-center border-dashed border-CorporatePrimary border-2 rounded-lg w-full h-full p-2'>

                <GridIcon size={QRData.size.width} />
                <span className="absolute -bottom-3 left-1/2 transform -translate-x-1/2  text-xs text-white bg-CorporatePrimary size-fit p-1.5 rounded-md ">
                    QR
                </span>
            </div>
        )
    }

    return (
        <Rnd
            default={{
                x: QRData.coordinates.x,
                y: QRData.coordinates.y,
                width: QRData.size.width,
                height: QRData.size.height,
            }}
            minWidth={45}
            maxWidth={150}
            minHeight={45}
            maxHeight={150}
            dragGrid={[10, 10]}
            lockAspectRatio={true}
            size={QRData.size}
            position={QRData.coordinates}
            onDragStop={(e, d) => {
                setQRData({
                    ...QRData,
                    coordinates: { x: d.x, y: d.y }
                });
            }}
            onResize={(e, direction, ref, delta, position) => {
                setQRData({
                    ...QRData,
                    size: { width: ref.offsetWidth, height: ref.offsetHeight },
                })
            }}
            enableResizing={{
                top: false,
                right: true,
                bottom: true,
                left: false,
                topRight: false,
                bottomRight: true,
                bottomLeft: false,
                topLeft: false
            }}

            bounds="parent"
        >
            <Box />
        </Rnd>
    )
}
