import React from 'react'
import { Template } from '../../types/templates'
import { DotsDropDown } from '../../../shared/components/DotsDropDown'
import { EditIcon } from '../../../shared/Icons/EditIcon'
import { FileIcon } from '../../../shared/Icons/FilenIcon'
import TrashIcon from '../../../shared/Icons/TrashIcon'

export interface ItemListTemplateProps {
    template: Template
    index: number
}

const items = [
    {
        name: 'Editar plantilla',
        icon: <FileIcon />,
        onClick: () => console.log('Editar plantilla')
    },
    {
        name: 'Cambiar nombre',
        icon: <EditIcon />,
        onClick: () => console.log('Cambiar nombre')
    },
    {
        name: 'Borrar',
        icon: <TrashIcon />,
        onClick: () => console.log('Borrar')
    }
]

export const ItemListTemplate = ({ index, template }: ItemListTemplateProps) => {
    return (
        <button className='h-28 flex flex-col p-4 gap-2 bg-white border-2 border-NeutralNeutral5'>
            <div className='flex size-full h-1/3 justify-between items-center bodymbold text-NeutralNeutral1'>
                {template.name}
                <DotsDropDown items={items} />
            </div>
            <div className='h-2/3'>
                <p className='bodym text-NeutralNeutral2'>{`${template.numberOfSignatures} firmas`}</p>
            </div>

        </button>
    )
}
