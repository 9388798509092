import type React from "react"

interface EditIconProps {
    size?: number
    color?: string
}

export const EditIcon: React.FC<EditIconProps> = ({ size = 14, color = "#94A3B8" }) => {
    return (
        <svg width={size} height={size} viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M9.63601 2.19849C10.234 1.6005 11.2035 1.6005 11.8015 2.19849C12.3995 2.79648 12.3995 3.76602 11.8015 4.36401L5.44778 10.7178C5.22433 10.9412 4.95905 11.1185 4.66709 11.2394L2.87614 11.9812C2.63091 12.0827 2.34865 12.0266 2.16097 11.8389C1.97329 11.6512 1.91715 11.3689 2.01873 11.1237L2.76052 9.33299C2.88144 9.04107 3.05868 8.77583 3.28211 8.5524L9.63601 2.19849Z"
                fill={color}
            />
        </svg>
    )
}

