import React, { useState } from 'react'
import { Link, Navigate, useNavigate } from 'react-router-dom'
import InputField from '../../shared/components/InputField'
import Button, { ButtonSize } from '../../shared/components/Button'
import { ROUTES } from '../../router/routes'
import { useDocumentTemplateContext } from '../context/DocumentTemplateContext'
import { useFileTemplate } from '../hooks/useFileTemplate'
import { useGetTemplates } from '../hooks/AllTemplates/useGetTemplates'
import { ModalCreateTemplate } from '../components/AllTemplates/ModalCreateTemplate'
import { ItemListTemplate } from '../components/AllTemplates/ItemListTemplate'
import { ListTemplates } from '../components/AllTemplates/ListTemplates'

interface AllTemplatesProps {
    // nombrePlantilla: string
    // onNombrePlantillaChange: (nombrePlantilla: string) => void
}




export const AllTemplatesPage = ({ }: AllTemplatesProps) => {
    const array = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]
    const [isModalCrearPlantilla, setIsModalCrearPlantilla] = useState(false)

    const { templates } = useGetTemplates();
    return (
        <div className='size-full p-4'>
            <div className='w-full h-20 flex justify-between items-center'>
                <p className='bodymbold text-NeutralNeutral2'>{`${templates.length} plantillas`}</p>
                <div className='size-fit'>

                    <Button
                        size={ButtonSize.medium}
                        text='Crear nueva plantilla'
                        onClick={() => setIsModalCrearPlantilla(true)}
                        hasBg={true} />
                </div>
            </div>
            <div>
                <ListTemplates />
            </div>

            {isModalCrearPlantilla &&
                <ModalCreateTemplate isOpen={isModalCrearPlantilla} onClose={setIsModalCrearPlantilla} />
            }
        </div>

    )
}
