import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import api from '../../shared/api/Axios'
import { DevelopersEndpoints } from '../../shared/api/services/ConfigApi'
import { OrganizationResponseDeveloper } from '../types/OrganizationResponseDeveloper'
import { Permission } from '../types/RolesYPermissions'
import { useDeveloperContext } from '../context/DeveloperContext'

const fetchPermissions = async (): Promise<Permission[]> => {
    const response = await api.get(DevelopersEndpoints.GePermisssions())
    return response.data
}

const fetchAllOrganizationsInfo = async (): Promise<OrganizationResponseDeveloper[]> => {
    const response = await api.get(DevelopersEndpoints.GetAllOrganizationsInfo())
    return response.data
}

const deletePermission = async (id: number): Promise<void> => {
    await api.delete(DevelopersEndpoints.DeletePermission(id))
}

const deleteRole = async (id: number): Promise<void> => {
    await api.delete(DevelopersEndpoints.DeleteRole(id))
}


const removePermissionFromRole = async ({
    roleId,
    permissionId,
}: { roleId: number; permissionId: number }): Promise<void> => {
    await api.delete(DevelopersEndpoints.DeletePermissionFromRole(roleId, permissionId))
}

const addPermissionToRole = async ({
    roleId,
    permissionId,
}: { roleId: number; permissionId: number }): Promise<void> => {
    await api.post(DevelopersEndpoints.AddPermissionToRole(roleId, permissionId))
}

const addPermission = async (name: string): Promise<void> => {
    await api.post(DevelopersEndpoints.AddPermission(name))
}

const addRolePredifinedToAllOrganizations = async (name: string): Promise<void> => {
    await api.post(DevelopersEndpoints.AddRolePredifinedToAllOrganizations(name))
}
const addRoleToOrganization = async ({ organizationId, name }: { organizationId: string; name: string }): Promise<void> => {
    await api.post(DevelopersEndpoints.AddRoleToOrganization(organizationId, name))
}

export const useApiDeveloper = () => {
    const queryClient = useQueryClient()
    const { setIsAddPermissionToRoleModalOpen, setIsAddPermissionModalOpen } = useDeveloperContext()

    const { data: fetchPermissionsData } = useQuery<Permission[]>({
        queryKey: ['permissions'],
        queryFn: async () => {
            return fetchPermissions();
        },
    });


    const { data: fetchOrganizationsInfo } = useQuery<OrganizationResponseDeveloper[]>({
        queryKey: ['organizationsInfo'],
        queryFn: async () => {
            return fetchAllOrganizationsInfo();
        },
    });


    const addRoleToOrganizationMutation = useMutation({
        mutationFn: addRoleToOrganization,
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ["organizationsInfo"] })
        },
    })

    const deletePermissionMutation = useMutation({
        mutationFn: deletePermission,
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ["permissions"] })
        },
    })

    const deleteRoleMutation = useMutation({
        mutationFn: deleteRole,
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ["organizationsInfo"] })
        },
    })

    const removePermissionFromRoleMutation = useMutation({
        mutationFn: removePermissionFromRole,
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ["organizationsInfo"] })
        },
    })

    const addPermissionToRoleMutation = useMutation({
        mutationFn: addPermissionToRole,
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ["organizationsInfo"] })
            setIsAddPermissionToRoleModalOpen(false)
        },
    })

    const addPermissionMutation = useMutation({
        mutationFn: addPermission,
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ["permissions"] })
            setIsAddPermissionModalOpen(false)
        },
    })

    const AddRolePredifinedToAllOrganizationsMutation = useMutation({
        mutationFn: addRolePredifinedToAllOrganizations,
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ["rolesAndPermissions"] })
            setIsAddPermissionModalOpen(false)
        },
    })


    return {
        fetchPermissionsData,
        fetchOrganizationsInfo,
        addRoleToOrganizationMutation,
        deletePermissionMutation,
        deleteRoleMutation,
        removePermissionFromRoleMutation,
        addPermissionToRoleMutation,
        addPermissionMutation,
        AddRolePredifinedToAllOrganizationsMutation,
    }
}
