import { Outlet } from 'react-router-dom'
import MainTopNav from '../../shared/components/nav/MainTopNav'

export const DocumentTemplateLayout = () => {
    return (
        <div className='flex flex-col bg-NeutralNeutral6  h-lvh'>
            <MainTopNav />
            {/* <TemplateTopNav /> */}
            <Outlet />
        </div>
    )
}
