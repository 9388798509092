export const checkImageBelowDimensions = (file: File, maxWidth: number, maxHeight: number): Promise<boolean> => {
    return new Promise((resolve) => {
        const img = new Image();
        img.onload = () => {
            URL.revokeObjectURL(img.src);
            resolve(img.width <= maxWidth && img.height <= maxHeight);
        };
        img.src = URL.createObjectURL(file);
    });
};


export const checkImageAboveDimensions = (file: File, maxWidth: number, maxHeight: number): Promise<boolean> => {
    return new Promise((resolve) => {
        const img = new Image();
        img.onload = () => {
            URL.revokeObjectURL(img.src);
            resolve(img.width >= maxWidth && img.height >= maxHeight);
        };
        img.src = URL.createObjectURL(file);
    });
};
