
  function padZero(num: number): string {
    return num.toString().padStart(2, '0')
  }
  
 const formatearFechaAHora = (fechaISO: string | null): string =>{
    if(!fechaISO) {return "N/A";}

    const date = new Date(fechaISO)
    const hours = padZero(date.getHours())
    const minutes = padZero(date.getMinutes())
    
    return `${hours}:${minutes}`
  };

  export default formatearFechaAHora;