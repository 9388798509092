import { ROUTES } from "../../../../router/routes";
import InboxIcon from "../../../Icons/InboxIcon";

export const navItems = [
  {
    id: 1,
    label: "Documentos",
    route: ROUTES.VISOR_DOCUMENTOS,
    icon: InboxIcon,
  },
  { id: 2, label: "Plantillas", route: ROUTES.ALL_TEMPLATES, icon: InboxIcon },
  { id: 3, label: "Estadísticas", route: ROUTES.ESTADISTICAS, icon: InboxIcon },
];
