import { Route } from "react-router-dom"
import { ROUTES } from "../../router/routes"
import { DevelopersPage } from "../pages/DevelopersPage"
import { DeveloperProvider } from "../context/DeveloperContext"

export const DevelopersRoutes = () => {
    return (
        <>
            <Route element={<DeveloperProvider />}>
                <Route
                    path={`${ROUTES.DEVELOPERS}`}
                    element={<DevelopersPage />}
                />
            </Route>
        </>
    )
}