import React from 'react'

export interface ToggleProps {
    isChecked: boolean
    onChange: (checked: boolean) => void
    label?: string
}

export const Toggle: React.FC<ToggleProps> = ({ label, isChecked, onChange }) => {
    return (
        <div className="flex items-center gap-4">
            <button
                role="switch"
                aria-checked={isChecked}
                onClick={() => onChange(!isChecked)}
                className={`relative w-12 h-6 p-1 rounded-full transition-colors duration-200 ${isChecked ? "bg-CorporatePrimary" : "bg-NeutralNeutral5"
                    }`}
            >
                <span
                    className={`block h-4 w-4 bg-white rounded-full shadow-sm transition-transform duration-200 ${isChecked ? "translate-x-6" : "translate-x-0"
                        }`}
                />
                <span className="sr-only">{isChecked ? "Activado" : "Desactivado"}</span>
            </button>
            {label && (
                <label
                    className=
                    {`${isChecked ? "text-NeutralNeutral1" : 'text-NeutralNeutral2'} cursor-pointer bodym`}
                    onClick={() => onChange(!isChecked)}
                >
                    {label}
                </label>
            )}
        </div>
    );
};


