import { Template } from "../../types/templates"

export const useGetTemplates = () => {

    const templates: Template[] = Array.from({ length: 10 }, (_, index) => ({
        id: (index + 1).toString(),
        name: `Nombre ${index + 1}`,
        numberOfSignatures: Math.floor(Math.random() * 5) + 1,
    }));
    return {
        templates
    }
}