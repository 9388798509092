import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../router/routes";
import { useDocumentTemplateContext } from "../context/DocumentTemplateContext";
import { usePdfScaling } from "./usePdfScaling";

export const useFileTemplate = () => {
    const navigate = useNavigate();
    const { setFileTemplate, nameDocument, setNameDocument, setPdfViewerDimensions, setPdfOriginalDimensions, pdfViewerDimensions, QRData, setQRData, TextValidationData, setTextValidationData, setFirmantes, firmantes } = useDocumentTemplateContext();

    const removeAllFileTemplate = () => {
        setFileTemplate([])
    }

    const setNameDocumentAndNavigate = (value: string) => {
        setNameDocument(value)
        if (nameDocument.trim()) {
            navigate(`${ROUTES.CREATE_DOCUMENT_TEMPLATE}/${nameDocument}`);
        }
    }

    const navigateWithNameDocument = () => {
        if (nameDocument.trim()) {
            navigate(`${ROUTES.CREATE_DOCUMENT_TEMPLATE}/${nameDocument}`);
        }
    }

    const handlePageLoadSuccess = (page: any) => {
        const { width, height, originalWidth, originalHeight } = page
        // if (width !== 0 && height !== 0)
        setPdfViewerDimensions({ width, height })
        setPdfOriginalDimensions({ width: originalWidth, height: originalHeight })
        if (pdfViewerDimensions) {

            const QRScaling = usePdfScaling({
                pdfFirstWidth: pdfViewerDimensions.width,
                pdfFirstHeight: pdfViewerDimensions.height,
                pdfFinalWidth: width,
                pdfFinalHeight: height,
                size: QRData.size,
                coordinates: QRData.coordinates
            })
            setQRData({
                size: QRScaling.scaledSize,
                coordinates: QRScaling.scaledCoordinates
            })

            const textValidatorScaling = usePdfScaling({
                pdfFirstWidth: pdfViewerDimensions.width,
                pdfFirstHeight: pdfViewerDimensions.height,
                pdfFinalWidth: width,
                pdfFinalHeight: height,
                size: TextValidationData.size,
                coordinates: TextValidationData.coordinates
            })

            setTextValidationData({
                size: textValidatorScaling.scaledSize,
                coordinates: textValidatorScaling.scaledCoordinates
            })

            const newFirmantes = firmantes.map((firmante) => {
                const scaling = usePdfScaling({
                    pdfFirstWidth: pdfViewerDimensions.width,
                    pdfFirstHeight: pdfViewerDimensions.height,
                    pdfFinalWidth: width,
                    pdfFinalHeight: height,
                    size: firmante.size,
                    coordinates: firmante.coordinates
                })
                return { ...firmante, size: scaling.scaledSize, coordinates: scaling.scaledCoordinates }
            })

            setFirmantes(newFirmantes)
        }


    }


    return { removeAllFileTemplate, nameDocument, setNameDocumentAndNavigate, setNameDocument, navigateWithNameDocument, handlePageLoadSuccess }
}