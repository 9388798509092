import React from 'react'

export const Skeleton = () => {
    return (
        <div className="w-ful  mx-auto my-4 p-4  shadow-lg rounded-lg overflow-hidden">
            <div className="animate-pulse flex flex-col">
                {/* Content area */}
                <div className="px-6  py-4 space-y-4">
                    {/* Name */}
                    <div className="h-4 bg-slate-200 rounded w-3/4"></div>

                    {/* Description */}
                    <div className="space-y-2">
                        <div className="h-3 bg-slate-200 rounded"></div>
                        <div className="h-3 bg-slate-200 rounded w-5/6"></div>
                    </div>
                </div>

                {/* Footer */}
                <div className="px-6 py-4 ">
                    <div className="flex justify-between">
                        <div className="h-6 w-16 bg-slate-200 rounded"></div>
                        <div className="h-6 w-16 bg-slate-200 rounded"></div>
                    </div>
                </div>
            </div>
        </div>
    )
}
