import React, { createContext, useContext, useState } from 'react'
import { Outlet } from 'react-router-dom'
import { Role } from '../types/RolesYPermissions'
import { OrganizationResponseDeveloper } from '../types/OrganizationResponseDeveloper'

interface DeveloperContextType {
    selectedRole: Role | null
    setSelectedRole: (role: Role | null) => void
    selectedOrganization: OrganizationResponseDeveloper | null
    setSelectedOrganization: (organization: OrganizationResponseDeveloper | null) => void
    isAddRoleToOrganization: boolean
    setIsAddRoleToOrganization: (value: boolean) => void
    isAddPermissionToRoleModalOpen: boolean
    setIsAddPermissionToRoleModalOpen: (value: boolean) => void
    permissionName: string
    setPermissionName: (value: string) => void
    isAddPermissionModalOpen: boolean
    setIsAddPermissionModalOpen: (value: boolean) => void
    roleName: string
    setRoleName: (value: string) => void
    selectedPermission: number | null
    setSelectedPermission: (value: number | null) => void
}

const DeveloperContext = createContext<DeveloperContextType | undefined>(undefined)

export const DeveloperProvider = () => {
    const [selectedRole, setSelectedRole] = useState<Role | null>(null)
    const [selectedOrganization, setSelectedOrganization] = useState<OrganizationResponseDeveloper | null>(null)
    const [isAddRoleToOrganization, setIsAddRoleToOrganization] = useState(false)
    const [isAddPermissionToRoleModalOpen, setIsAddPermissionToRoleModalOpen] = useState(false)
    const [permissionName, setPermissionName] = useState<string>("")
    const [isAddPermissionModalOpen, setIsAddPermissionModalOpen] = useState(false)
    const [roleName, setRoleName] = useState<string>("")
    const [selectedPermission, setSelectedPermission] = useState<number | null>(null)
    return (
        <DeveloperContext.Provider value={{
            selectedRole,
            setSelectedRole,
            selectedOrganization,
            setSelectedOrganization,
            isAddRoleToOrganization,
            setIsAddRoleToOrganization,
            isAddPermissionToRoleModalOpen,
            setIsAddPermissionToRoleModalOpen,
            permissionName,
            setPermissionName,
            isAddPermissionModalOpen,
            setIsAddPermissionModalOpen,
            roleName,
            setRoleName,
            selectedPermission,
            setSelectedPermission
        }}>
            <Outlet />
        </DeveloperContext.Provider>
    )
}

export const useDeveloperContext = () => {
    const context = useContext(DeveloperContext)
    if (context === undefined) {
        throw new Error("DeveloperContext must be used within a DeveloperProvider")
    }
    return context
}
