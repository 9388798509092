import axios from "axios";

const Header_X_DS_TOKEN = process.env.REACT_APP_HEADER_X_DS_TOKEN as string;
const Header_X_WEB_KEY = process.env.REACT_APP_HEADER_X_WEB_KEY as string;
const Header_X_EXTRA_INFO = process.env.REACT_APP_HEADER_X_EXTRA_INFO as string;
const API_BASE_URL = process.env.REACT_APP_API_AUTHLXP_URL as string;

const apiAuthLXP = axios.create({
  baseURL: API_BASE_URL + "/",
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
  },
});

const apiLogOutAuthLXP = axios.create({
  baseURL: API_BASE_URL + "/",
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
    "X-DS-TOKEN": Header_X_DS_TOKEN,
    "X-EXTRA-INFO": Header_X_EXTRA_INFO,
    "X-WEB-KEY": Header_X_WEB_KEY,
  },
});

export { apiLogOutAuthLXP };
export default apiAuthLXP;
