import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import logo from '../../../assets/icons/logo.svg';
import { ROUTES } from '../../../router/routes';
import OrganizationSelector from './OrganizationSelector';
import { navItems } from './types/NavItems';

export interface DesktopNavigationProps {
    activeButtonNav: number;
    setActiveButtonNav: (number: number) => void;
    refetchDocuments?: (page: number) => void;
}

export const DesktopNavigation: React.FC<DesktopNavigationProps> = ({ activeButtonNav, setActiveButtonNav, refetchDocuments }) => {
    const location = useLocation()

    return (
        <div className="hidden md:flex md:w-full md:pl-3 md:items-center md:justify-between gap-4">
            <img src={logo} alt="Logo" className="hidden md:block size-14" />
            <div className="flex-none w-1/4 flex items-center justify-center h-full">
                <OrganizationSelector refetchDocuments={refetchDocuments} />
            </div>
            <div className="flex-grow flex justify-center">
                <ul className="flex gap-6">
                    {navItems.map((item) => (
                        <li key={item.id}>
                            <Link
                                to={item.route}
                                className={`bodylbold text-NeutralNeutral1 ${location.pathname === item.route ? "underline underline-offset-4 decoration-2" : ""}`}
                            >
                                {item.label}
                            </Link>

                        </li>
                    ))}
                </ul>
            </div>
            <div className="flex-none w-1/4">
                {/* Este div vacío ayuda a equilibrar el diseño */}
            </div>
        </div>
    )
}
