import InputField from '../../shared/components/InputField';
import { useDeveloperContext } from '../context/DeveloperContext';
import { useApiDeveloper } from '../hooks/useApiDeveloper';

export const AddPermissionModal = () => {
    const { setIsAddPermissionModalOpen, permissionName, setPermissionName } = useDeveloperContext();

    const { addPermissionMutation, addPermissionToRoleMutation } = useApiDeveloper();

    return (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
            <div className="bg-white p-6 rounded-lg">
                <h2 className="bodylbold text-NeutralNeutral1 mb-4">Añadir permiso</h2>
                <InputField value={permissionName} type='text' placeholder='Nombre del permiso' onChange={(e) => setPermissionName(e.target.value)} />
                <div className="flex justify-end">
                    <button className="px-4 py-2 bg-gray-200 rounded mr-2" onClick={() => setIsAddPermissionModalOpen(false)}>
                        Cancelar
                    </button>
                    <button
                        className="px-4 py-2 bg-blue-500 text-white rounded"
                        onClick={() => {
                            setIsAddPermissionModalOpen(false)
                            addPermissionMutation.mutate(permissionName)
                        }}
                        disabled={addPermissionToRoleMutation.isPending}
                    >
                        Añadir
                    </button>
                </div>
            </div>
        </div>
    )
}
