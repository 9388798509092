import React from 'react'

interface ImageIconProps {
    width?: number
    height?: number
    fill?: string
    className?: string
}

export const ImageIcon = ({
    width = 42,
    height = 34,
    fill = '#94A3B8',
    className,
    ...props
}: ImageIconProps) => {
    return (
        <svg
            width={width}
            height={height}
            viewBox="0 0 42 34"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            role="img"
            aria-label="Image icon"
            className={className}
            {...props}
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M0.299805 6.07539C0.299805 3.21732 2.61673 0.900391 5.47481 0.900391H36.5248C39.3829 0.900391 41.6998 3.21732 41.6998 6.07539V27.9254C41.6998 30.7835 39.3829 33.1004 36.5248 33.1004H5.4748C2.61673 33.1004 0.299805 30.7835 0.299805 27.9254V6.07539ZM3.7498 19.4399V27.9254C3.7498 28.8781 4.52211 29.6504 5.4748 29.6504H36.5248C37.4775 29.6504 38.2498 28.8781 38.2498 27.9254V21.7399L33.1446 16.6347C32.4709 15.961 31.3787 15.961 30.705 16.6347L26.3143 21.0254L27.3946 22.1056C28.0682 22.7793 28.0682 23.8715 27.3946 24.5451C26.7209 25.2188 25.6287 25.2188 24.955 24.5451L13.0196 12.6097C12.3459 11.936 11.2537 11.936 10.58 12.6097L3.7498 19.4399ZM25.5998 10.1004C25.5998 11.3706 24.5701 12.4004 23.2998 12.4004C22.0296 12.4004 20.9998 11.3706 20.9998 10.1004C20.9998 8.83013 22.0296 7.80039 23.2998 7.80039C24.5701 7.80039 25.5998 8.83013 25.5998 10.1004Z"
                fill={fill}
            />
        </svg>
    )
}

