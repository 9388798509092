import React from 'react'

interface ReturnIconProps {
    size?: number
}

export default function ReturnIcon({ size = 40 }: ReturnIconProps) {
    return (
        <svg
            width={size}
            height={size}
            viewBox="0 0 40 40"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <rect width={size} height={size} rx={size / 2} fill="#F1F5F9" />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M28.4 20.0001C28.4 20.4971 27.997 20.9001 27.5 20.9001L14.7345 20.9L19.7238 25.6513C20.0821 25.9958 20.0933 26.5656 19.7487 26.9238C19.4042 27.2821 18.8345 27.2933 18.4762 26.9488L11.8762 20.6488C11.6997 20.4791 11.6 20.2449 11.6 20C11.6 19.7552 11.6997 19.521 11.8762 19.3513L18.4762 13.0513C18.8345 12.7068 19.4042 12.718 19.7487 13.0763C20.0933 13.4345 20.0821 14.0043 19.7238 14.3488L14.7345 19.1L27.5 19.1001C27.997 19.1001 28.4 19.503 28.4 20.0001Z"
                fill="#64758B"
            />
        </svg>
    )
}