import React from 'react';
import { DayPicker } from "react-day-picker"
import InputField from './InputField';
import { es } from "date-fns/locale"
import { CustomDatePicker } from './CustomDatePicker';
import { format } from 'date-fns';
interface DateInputProps {
    id: string;
    label: string;
    value: Date;
    onChange: (date: Date) => void;
}

export const DateInput: React.FC<DateInputProps> = ({
    id,
    label,
    value,
    onChange,
}) => {

    const [isOpen, setIsOpen] = React.useState(false)

    return (
        <div className='flex flex-col gap-2 grow'>
            <label
                htmlFor={id}
                className='text-NeutralNeutral3'>{label}
            </label>
            <div className='grid gap-2 relative'>
                <InputField
                    id={id}
                    type='text'
                    value={value ? format(value, 'dd/MM/yyyy') : ''}
                    placeholder='dd/mm/yyyy'
                    onClick={() => setIsOpen(!isOpen)}
                />
                <CustomDatePicker
                    value={value}
                    onChange={onChange}
                    isOpen={isOpen}
                    setIsOpen={setIsOpen}
                />
            </div>


        </div>
    );
};

