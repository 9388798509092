// Interfaces
export interface DocumentListResponse {
  allDocuments: DocumentListGroupedPaginated<AllDocument>;
  pendingDocuments: DocumentListGroupedPaginated<PendingDocument>;
  inProgressDocuments: DocumentListGroupedPaginated<InProgressDocument>;
  completedDocuments: DocumentListGroupedPaginated<CompletedDocument>;
}

export interface DocumentListGroupedPaginated<T extends DocumentBase>
  extends IPagination {
  documentsGrouped: DocumentListGrouped<T>[];
}

export interface DocumentListGrouped<T extends DocumentBase> {
  createdDay: Date;
  documents: T[];
}

export interface IPagination {
  page: number;
  pageSize: number;
  totalCount: number;
  hasNextPage: boolean;
  hasPreviousPage: boolean;
}

export interface DocumentBase {
  id: string;
  documentName: string;
  signProcessId: string;
  updatedDate?: Date;
}

export interface AllDocument extends DocumentBase {
  documentStatus: DocumentStatusEnum;
}

export interface InProgressDocument extends DocumentBase {}
export interface PendingDocument extends DocumentBase {}

export interface CompletedDocument extends DocumentBase {}

// Enum
export enum DocumentStatusEnum {
  All,
  Pending,
  InProgress,
  Completed,
}
