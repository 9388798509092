import React from 'react'

interface DocumentTemplateStatistics {
    documentTemplateId: string
    documentTemplateName: string
    totalSignatures: number
}

interface OrganizationStatisticsBlockProps {
    title: string
    description: string
    totalSignatures: number
    documentTemplates: DocumentTemplateStatistics[]
}

export const OrganizationStatisticsBlock: React.FC<OrganizationStatisticsBlockProps> = ({
    title,
    description,
    totalSignatures,
    documentTemplates
}) => {
    return (
        <section className='flex flex-col gap-2 p-4 bg-white rounded-lg'>
            <div className='flex justify-between pb-3'>
                <p className='h6 text-NeutralNeutral1'>{title}</p>
                <p className='h6 text-NeutralNeutral2'>{totalSignatures} firmas</p>
            </div>
            {documentTemplates.map((template) => (
                <div key={template.documentTemplateId} className="flex flex-col gap-2 border-b-[2px] last:border-0 border-NeutralNeutral5">
                    <div className="flex w-full items-center">
                        <p className='bodylbold text-NeutralNeutral2 flex-grow truncate pr-2' style={{ width: '40%' }}>{template.documentTemplateName}</p>
                        <p className='bodylbold text-NeutralNeutral2 text-left' style={{ width: '60%' }}>{template.totalSignatures} créditos/firmas</p>
                    </div>
                </div>
            ))}
        </section>
    )
}

