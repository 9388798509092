

import React from 'react';
import InputField from '../../../shared/components/InputField';
import { Toggle } from '../../../shared/components/Toggle';
import { DropZone, MaxDimensions, MinDimensions } from '../../../shared/components/DropZone';
import { Accept } from 'react-dropzone/.'
import { Dropdown } from '../../../shared/components/DropDown';
import { Accordion } from '../../../shared/components/Accordion';
import { FirmanteData } from '../../types';

interface FirmanteProps {
  firma: FirmanteData;
  index: number;
  onNombreChange: (value: string) => void;
  onAñadirLogoChange: (value: boolean) => void;
  onFileLogoChange: (files: File[]) => void;
  acceptedFileTypes?: Accept,
  numberOfPages: number
  onPageChange: (page: number) => void
  onReasonChange: (reason: string) => void
}

const minLogoDimensions: MinDimensions = {
  Width: 250,
  Height: 250,
};


export const SignatoryDropZone: React.FC<FirmanteProps> = ({
  index,
  onNombreChange,
  onAñadirLogoChange,
  onFileLogoChange,
  acceptedFileTypes,
  numberOfPages,
  onPageChange,
  onReasonChange,
  firma
}) => {
  return (
    <div className='flex flex-col justify-center gap-2 items-center'>
      {/* <div>
        Dimensiones firma {index + 1}: {firma.size.width.toFixed(2)}px x {firma.size.height.toFixed(2)}px
      </div>
      <div>
        Coordenadas firma {index + 1}: {firma.coordinates.x.toFixed(2)}px x {firma.coordinates.y.toFixed(2)}px
      </div> */}
      <div className='flex justify-start items-center size-full'>
        {/* <p className='bodymbold text-NeutralNeutral2 text-start text-nowrap pr-3'>Firma {index + 1}:</p> */}

        <Accordion title={

          <InputField
            placeholder={`Firma ${index + 1}`}
            type='text'
            value={firma.nombreFirma}
            onChange={(e) => onNombreChange(e.target.value)}
            whithOutBackground={true}
          />

        }
          titleNoClick={true}>
          <div className='flex items-center gap-2 size-full justify-between'>
            <div className='flex flex-col'>
              <label className=" bodym text-NeutralNeutral2 ">
                Motivo de la firma
              </label>
              <InputField
                placeholder={"Por ejemplo: Razón"}
                type='text'
                value={firma.reason}
                onChange={(e) => onReasonChange(e.target.value)}
              />
            </div>
            <div className='flex flex-col'>
              <label className=" bodym text-NeutralNeutral2 ">
                Página
              </label>
              <Dropdown
                label="Página"
                options={Array.from(Array(numberOfPages).keys()).map(i => (i + 1).toString())}
                value={firma.page.toString()}
                onChange={(e) => onPageChange(parseInt(e))}
              />
            </div>



          </div>

          <div className='w-full'>
            <Toggle
              isChecked={firma.isAñadirLogo}
              onChange={onAñadirLogoChange}
              label='Añadir logo'
            />
          </div>
          {firma.isAñadirLogo && (
            <>
              <DropZone
                files={firma.fileLogo}
                onFilesChange={onFileLogoChange}
                acceptedFileTypes={acceptedFileTypes}
                minDimensions={minLogoDimensions}
              />
              {firma.fileLogo.length === 0 &&
                <>
                  <p className='bodym text-NeutralNeutral2'>Formato aceptado: PNG</p>
                  <p className='bodym text-NeutralNeutral2'> Mínima resolución: 250x250 px</p>
                </>

              }

            </>
          )}
        </Accordion>

      </div>

    </div>

  );
};

