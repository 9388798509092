import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { VisorDocumentos } from "../documents/components/VisorDocumentos";
import { DocumentsLayout } from "../documents/layouts/DocumentsLayout";
import { TopNavLayoutWithDocumentContext } from "../shared/layouts/TopNavLayout";
import { ExternalLoginPage } from "../auth/pages/ExternalLoginPage";
import { Faqs } from "../faqs/pages/Faqs";
import { StatisticsRoutes } from "../statistics/routes/StatisticsRoutes";
import { TemplatesRoutes } from "../Templates/routes/TemplatesRoutes";
import { ROUTES } from "./routes";
import { DocumentosContextProvider } from "../documents/context/DocumentosContext";
export const PrivateRoutes = () => {
  return (
    <Routes>
      {/* Ruta raíz, que será la página inicial */}
      <Route
        index
        element={<Navigate to={ROUTES.VISOR_DOCUMENTOS} replace />}
      />

      <Route path={ROUTES.EXTERNALLOGIN} element={<ExternalLoginPage />} />
      <Route path="/*" element={<Navigate to={ROUTES.VISOR_DOCUMENTOS} />} />
      {StatisticsRoutes()}
      <Route element={<DocumentosContextProvider />}>
        <Route element={<TopNavLayoutWithDocumentContext />}>
          <Route element={<DocumentsLayout />}>
            <Route
              path={ROUTES.VISOR_DOCUMENTOS}
              element={<VisorDocumentos />}
            />
          </Route>
          <Route path={ROUTES.FAQS} element={<Faqs />} />
        </Route>
      </Route>
      {TemplatesRoutes()}
    </Routes>
  );
};
