const loginCodeBaseUrl = process.env.REACT_APP_LOGIN_CODE_AUTH as string;
const clientIdAuth = process.env.REACT_APP_CLIENTID_AUTH as string;

export const DocumentEndpoints = {
  getDocumentList: (
    pageAll: number,
    pageCompleted: number,
    pagePending: number,
    pageInProgress: number,
    pageSize: number,
    documentNameSearch?: string | null,
  ) => {
    const params = new URLSearchParams({
      pageAll: pageAll.toString(),
      pagePending: pagePending.toString(),
      pageCompleted: pageCompleted.toString(),
      pageInProgress: pageInProgress.toString(),
      pageSize: pageSize.toString(),
    });

    if (documentNameSearch != null && documentNameSearch.trim() !== "") {
      params.append("documentNameSearch", documentNameSearch.trim());
    }

    return `Document/GetDocumentList?${params.toString()}`;
  },
  SearchDocumentsByDocumentName: (
    search: string,
    page: number,
    pageSize: number,
  ) =>
    `Document/SearchDocumentsByDocumentName?documentName=${search}&page=${page}&pageSize=${pageSize}`,
  getDocumentStatus: (signProcessId: string | undefined) =>
    `Document/GetDocumentStatus?SignProcessIdRequest=${signProcessId}`,
  getFile: (signProcessId: string) =>
    `Document/GetFile?documentId=${signProcessId}`,
  sign: () => `Document/Sign`,
};
export const SecurityEndpoints = {
  refreshToken: (refreshTokenValue: string) =>
    `Security/RefreshToken?refreshTokenValue=${refreshTokenValue}`,

  externalLogin: (externalId: string) =>
    `Security/ExternalLogin?externalId=${externalId}`,

  LoginCodeAuth: (code: string) =>
    `${loginCodeBaseUrl}?code=${code}&ClientId=${clientIdAuth}`,

  LogOutAuth: (externalId: string) =>
    `erp/admin/users/${externalId}/logout?ClientId=${clientIdAuth}`,

  login: () => "Security/Login",
  getMe: () => "Security/GetMe",
  changeOrganization: () => "Security/ChangeOrganization",
  RegisterNewUser: () => "Security/RegisterNewUser",
};

export const StatisticEndpoints = {
  getStatistics: (startDate: string, endDate: string) =>
    `Statistics/GetStatistic?startDate=${startDate}&endDate=${endDate}`,
};

export const DevelopersEndpoints = {
  GetRolesAndPermissionsPredefined: () =>
    `Developer/GetRolesAndPermissionsPredefined`,
  GePermisssions: () => `Developer/GetPermissions`,
  DeletePermission: (id: number) => `Developer/DeletePermission?id=${id}`,
  DeleteRole: (id: number) => `Developer/DeleteRole?id=${id}`,
  DeletePermissionFromRole: (idRole: number, idPermission: number) =>
    `Developer/RemovePermissionFromRole?roleId=${idRole}&permissionId=${idPermission}`,
  AddPermissionToRole: (idRole: number, idPermission: number) =>
    `Developer/AddPermissionToRole?roleId=${idRole}&permissionId=${idPermission}`,
  AddPermission: (name: string) => `Developer/AddPermission?name=${name}`,
  AddRolePredifinedToAllOrganizations: (name: string) =>
    `Developer/AddRolePredifinedToAllOrganizations?name=${name}`,
  AddRoleToOrganization: (organizationId: string, name: string) =>
    `Developer/AddRoleToOrganization?organizationId=${organizationId}&name=${name}`,
  GetAllOrganizationsInfo: () => `Developer/GetAllOrganizationsInfo`,
};
