import { useQuery } from '@tanstack/react-query';
import RegisterIcon from "../../assets/icons/registerValid.svg";
import api from '../../shared/api/Axios';
import { SecurityEndpoints } from '../../shared/api/services/ConfigApi';
import { useInfoLocalStorage } from '../hooks/useInfoLocalStorage';


export const LoginErrorPage = () => {
    const { getExternalUserIdLocalStorage } = useInfoLocalStorage()
    const externalId = getExternalUserIdLocalStorage()
    const { } = useQuery({
        queryKey: [],
        queryFn: async () => {
            const newUser = {
                externalId: externalId,
            }
            return await api.post(SecurityEndpoints.RegisterNewUser(), newUser)
        },
        retry: false,
        refetchOnWindowFocus: false,
        refetchOnMount: false,
    })


    return (
        <div className="h-screen w-screen ">
            <div className="flex flex-col items-center w-full  h-full px-8 py-6 gap-5 md:flex md:flex-row md:justify-evenly md:items-center ">
                <div className="border p-6 bodym flex flex-row md:items-center">
                    <div className="basis-1/12">
                        <img src={RegisterIcon} alt="check" />
                    </div>
                    <div className="basis-11/12">
                        <p className="h6 text-NeutralNeutral1 mb-4">Estamos procesando tu solicitud.</p>
                        <p className="text-NeutralNeutral2">El alta de usuarios en EducaSign requiere de una validación manual por parte de nuestro equipo. Una vez validado tu perfil, recibirás una notificación por correo electrónico.</p>
                    </div>
                </div>
            </div>
        </div>
    )
}
