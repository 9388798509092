
import { formatearFecha } from '../../shared/utils/dates/formatearFecha';
import { FilterDocument } from '../context/DocumentosContext';
import { ItemListaDocumentos } from './ItemListaDocumentos';

interface DocumentoGroupByFechaProps {
    fecha: string;
    documentos: FilterDocument[];
    selectedDocuments?: string[];
    onDocumentSelect?: (documentId: string) => void;
}

export const DocumentoGroupByFecha = ({ fecha, documentos, selectedDocuments, onDocumentSelect }: DocumentoGroupByFechaProps) => {
    return (
        <div className=''>
            <h2 className='bodymbold text-NeutralNeutral2 mb-2'>Publicado el {formatearFecha(fecha)}</h2>
            <ul className='flex flex-col gap-2'>
                {documentos.map((doc, index) => (
                    <ItemListaDocumentos key={doc.id}
                        documento={doc}
                        isSelected={selectedDocuments && selectedDocuments.includes(doc.id)}
                        onDocumentSelect={onDocumentSelect} />
                ))}
            </ul>
        </div>
    )
}
