import React from 'react'

interface SuccessCheckIconProps {
  size?: number
  backgroundColor?: string
  checkColor?: string
  className?: string
}

export default function CheckCircle({
  size = 16,
  backgroundColor = "#32BB78",
  checkColor = "white",
  className = ""
}: SuccessCheckIconProps) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 56 56"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      aria-hidden="true"
    >
      <rect width="56" height="56" rx="28" fill={backgroundColor} />
      <path
        d="M18 29L26 37L38 19"
        stroke={checkColor}
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}