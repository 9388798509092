export const TranslationKeys = {
  DocumentoFirmado: "documentoFirmado",
  SeEnvioElXyFirmadoElX: "seEnvioElXyFirmadoElX",

  DocumentoNoValido: "documentoNoValido",
  DocumentoNoEmitido: "documentoNoEmitido",
  VolverAIntentar: "volverAIntentar",

  DocumentoPendienteDeFirmar: "documentoPendienteDeFirmar",
  DocumentoEnviadoConFecha: "documentoEnviadoConFecha",
  DescargarDocumento: "descargarDocumento",
  HerramientaDeValidacion: "herramientaDeValidacion",
  Acceder: "acceder",
  RegistroEnEducaSign: "registroEnEducaSign",
};
