
import { Navigate, Route, Routes } from "react-router-dom";
import { TopNavLayout, TopNavLayoutWithDocumentContext } from "../../shared/layouts/TopNavLayout";
import { StatisticsPage } from "../pages/StatisticsPage";
import { ROUTES } from "../../router/routes";

export const StatisticsRoutes = () => {
    return (
        <>
            <Route element={<TopNavLayout />}>
                <Route path={ROUTES.ESTADISTICAS} element={<StatisticsPage />} />
            </Route>
        </>
    )
}
