import type React from "react"

interface TrashIconProps {
    width?: number
    height?: number
    color?: string
}

const TrashIcon: React.FC<TrashIconProps> = ({ width = 12, height = 13, color = "#94A3B8" }) => {
    return (
        <svg width={width} height={height} viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M3 2.25V3H0.75C0.335786 3 0 3.33579 0 3.75C0 4.16421 0.335786 4.5 0.75 4.5H1.05L1.86493 12.6493C1.94161 13.4161 2.58685 14 3.35748 14H8.64252C9.41315 14 10.0584 13.4161 10.1351 12.6493L10.95 4.5H11.25C11.6642 4.5 12 4.16421 12 3.75C12 3.33579 11.6642 3 11.25 3H9V2.25C9 1.00736 7.99264 0 6.75 0H5.25C4.00736 0 3 1.00736 3 2.25ZM5.25 1.5C4.83579 1.5 4.5 1.83579 4.5 2.25V3H7.5V2.25C7.5 1.83579 7.16421 1.5 6.75 1.5H5.25ZM4.05044 5.00094C4.46413 4.98025 4.81627 5.29885 4.83696 5.71255L5.11195 11.2125C5.13264 11.6262 4.81404 11.9784 4.40034 11.9991C3.98665 12.0197 3.63451 11.7011 3.61383 11.2875L3.33883 5.78745C3.31814 5.37376 3.63674 5.02162 4.05044 5.00094ZM7.95034 5.00094C8.36404 5.02162 8.68264 5.37376 8.66195 5.78745L8.38696 11.2875C8.36627 11.7011 8.01413 12.0197 7.60044 11.9991C7.18674 11.9784 6.86814 11.6262 6.88883 11.2125L7.16383 5.71255C7.18451 5.29885 7.53665 4.98025 7.95034 5.00094Z"
                fill={color}
            />
        </svg>
    )
}

export default TrashIcon

