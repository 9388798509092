import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { useAuthContext } from '../../../auth/context/AuthContext';
import { ROUTES } from '../../../router/routes';
import { useAuth } from '../../../auth/hooks/useAuth';


export const UserAvatar = () => {
    const { logout } = useAuth()
    const { nameUser } = useAuthContext()
    const firstLetterName = nameUser && typeof nameUser === 'string' ? nameUser.charAt(0).toUpperCase() : ' '
    const [isOpen, setIsOpen] = useState(false)
    const dropdownRef = useRef<HTMLDivElement>(null)

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
                setIsOpen(false)
            }
        }

        document.addEventListener('mousedown', handleClickOutside)
        return () => {
            document.removeEventListener('mousedown', handleClickOutside)
        }
    }, [])


    return (
        <div className="relative" ref={dropdownRef}>
            <button
                className='flex justify-center items-center gap-3 size-full'
                onClick={() => setIsOpen(!isOpen)}
                aria-haspopup="true"
                aria-expanded={isOpen}
            >
                <div className="bg-CorporatePrimary rounded-full border-4 border-green-100 aspect-square size-10 flex justify-center items-center">
                    <p className="font-bold text-white">{firstLetterName}</p>
                </div>
                <div className='hidden md:block'>
                    <p className="button16 text-NeutralNeutral2 text-ellipsis whitespace-nowrap max-w-24 overflow-hidden">
                        {nameUser}
                    </p>
                </div>
                <div className='hidden md:block'>
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 text-NeutralNeutral2" viewBox="0 0 20 20" fill="currentColor">
                        <path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd" />
                    </svg>
                </div>
            </button>
            {isOpen && (
                <div className="absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5  z-50">
                    <div className="py-1" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
                        {/* {accounts.map((account, index) => (
              <button
                key={index}
                className="flex items-center w-full px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
                role="menuitem"
              >
                <div className={`rounded-full ${account.isSelected ? 'bg-primary' : 'bg-gray-200'} aspect-square size-8 flex justify-center items-center mr-3`}>
                  <p className="font-bold text-white text-sm">
                    {account.name.charAt(0)}
                  </p>
                </div>
                <span className="flex-1">{account.name}</span>
                {account.isSelected && (
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 text-primary" viewBox="0 0 20 20" fill="currentColor">
                    <path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd" />
                  </svg>
                )}
              </button>
            ))} */}
                        {/* <button
              className="flex items-center w-full px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
              role="menuitem"
            >
              <div className="rounded-full bg-gray-100 aspect-square size-8 flex justify-center items-center mr-3">
                <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 text-gray-500" viewBox="0 0 20 20" fill="currentColor">
                  <path fillRule="evenodd" d="M10 5a1 1 0 011 1v3h3a1 1 0 110 2h-3v3a1 1 0 11-2 0v-3H6a1 1 0 110-2h3V6a1 1 0 011-1z" clipRule="evenodd" />
                </svg>
              </div>
              <span className="text-text-secondary">Añadir cuenta</span>
            </button>
            <div className="border-t border-gray-100"></div> */}
                        <Link to={ROUTES.FAQS} className="flex items-center justify-center w-full px-4 py-2 bodyl hover:bg-gray-100" role="menuitem">Ayuda</Link>
                        <button
                            className="flex items-center justify-center w-full px-4 py-2 bodyl text-red-500 hover:bg-gray-100 hover:text-red-700"
                            role="menuitem"
                            onClick={() => {
                                logout()
                            }}
                        >
                            Cerrar sesión
                        </button>
                    </div>
                </div>
            )}
        </div>

    )
}
