import { Rnd } from 'react-rnd';
import { Coordinates, QRData, Size, TextValidationData } from '../../types';
import { useDocumentTemplateContext } from '../../context/DocumentTemplateContext';


export const DraggableBoxURLValidation = () => {

    const {
        isUrlValidacion,
        isAñadirTexto,
        orientationValidation,
        TextValidationData,
        setTextValidationData
    } = useDocumentTemplateContext()

    if (!(orientationValidation === "Horizontal" || orientationValidation === "Vertical"))
        return <></>

    const BoxURL = () => {
        const text = `${isAñadirTexto ? 'Texto Legal' : ''}${isAñadirTexto && isUrlValidacion ? ' y ' : ''}${isUrlValidacion ? 'URL de validación' : ''}`;
        return (
            <div className='relative border-dashed border-CorporatePrimary border-2 rounded-lg w-full h-full flex flex-col items-center justify-between'>
                {/* {
                    orientationValidation === 'Horizontal' ? */}
                <span className="absolute -bottom-3 left-1/2 transform -translate-x-1/2  text-xs text-white bg-CorporatePrimary size-fit p-1.5 rounded-md whitespace-nowrap">
                    {text}
                </span>
            </div>
        )
    }

    const isVertical = orientationValidation === "Vertical"

    return (
        <>
            <Rnd

                // className={`${isVertical ? "rotate-90" : ""} `}
                default={{
                    x: TextValidationData.coordinates.x,
                    y: TextValidationData.coordinates.y,
                    width: isVertical ? TextValidationData.size.height : TextValidationData.size.width,
                    height: isVertical ? TextValidationData.size.width : TextValidationData.size.height,
                }}
                minWidth={isVertical ? 20 : 100}
                maxWidth={isVertical ? 20 : 500}
                minHeight={isVertical ? 100 : 20}
                maxHeight={isVertical ? 500 : 20}
                dragGrid={[10, 10]}
                onDragStop={(e, d) => {
                    setTextValidationData({
                        ...TextValidationData,
                        coordinates: { x: d.x, y: d.y }
                    });
                }}
                onResizeStop={(e, direction, ref, delta, position) => {
                    setTextValidationData({
                        ...TextValidationData,
                        size: {
                            width: isVertical ? ref.offsetHeight : ref.offsetWidth,
                            height: isVertical ? ref.offsetWidth : ref.offsetHeight,
                        },
                    })
                }}
                enableResizing={{
                    top: false,
                    right: false,
                    bottom: false,
                    left: false,
                    topRight: false,
                    bottomRight: false,
                    bottomLeft: false,
                    topLeft: false
                }}
                size={{
                    width: isVertical ? TextValidationData.size.height : TextValidationData.size.width,
                    height: isVertical ? TextValidationData.size.width : TextValidationData.size.height,
                }}
                position={TextValidationData.coordinates}
                bounds="parent"
            >
                <BoxURL />
            </Rnd>
        </>

    )
}
